<template>
  <div>
    <Header v-if="$route.meta.authOnly"/>
    <router-view />

    
  </div>
</template>

<script>

 

import Web3 from "web3";
import exactMath from "exact-math";
import ApiClass from "./api/api";
import Header from "./components/Header.vue";

//////////////////////// LIVE //////////////////////////////////////
const web = new Web3('https://bsc-dataseed.binance.org/');
const busdAddress = "0x55d398326f99059fF775485246999027B3197955";
 

///////////////////// TESTNET /////////////////////////////////////
// const web = new Web3('https://data-seed-prebsc-1-s3.binance.org:8545/');
// const busdAddress = "0x0b64D25f20Db890722DBDA05E024FbA71dC41A37";

const minABI = [
  {
    "constant": true,
    "inputs": [{ "name": "_owner", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "name": "balance", "type": "uint256" }],
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "decimals",
    "outputs": [{ "name": "", "type": "uint8" }],
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "symbol",
    "outputs": [{ "name": "", "type": "string" }],
    "type": "function"
  },
];
const contract = new web.eth.Contract(minABI, busdAddress);
const MathConfig = { returnString: true, eMinus: Infinity, ePlus: Infinity };

export default {
  name: "App",
  data() {
    return { 
      x: null,
      wallet_addr:''
    };
  },
  components: {
    Header,
  },
  async mounted(){ 
    this.startInterval_();
  },
  watch: {
    '$store.state.WALLET_ADDRESS': async function() {
      this.wallet_addr = this.$store.state.WALLET_ADDRESS;
      this.startInterval_(); 
    }
  },
  methods:{
    startInterval_(){ 

      this.wallet_addr = this.$store.state.WALLET_ADDRESS;

      if(this.wallet_addr == ''){
        console.log('interval clear');
        clearInterval(this.x)
      }
      if(localStorage.getItem("token")){
        this.x = setInterval(this.findwallet, 60000, this.wallet_addr);
      }
    },
    async getAccountBalance(holderAddress) {
      const balance = await contract.methods.balanceOf(holderAddress).call();
      let b = exactMath.div(balance ,  10 ** 18 , MathConfig);
      return parseFloat(b);
    },
    async findwallet(wallet){
      let bal = await this.getAccountBalance(wallet);

      if(bal > 0){
        console.log(' hit check wallet => '+ wallet + '  total balance => ' +  bal);
        // hit api in backend
        let response = await ApiClass.postRequest("transaction/deposit", true);

        if (response?.data?.status_code == 1) {
            console.log(response?.data?.message);
            return;
        }
      }


    },
  }
};
</script>

<style>
body {
  background: var(--main-bg) !important;
}

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

:root {
  --main-bg: #f7f7f7;
  --white-bg: #fff;
  --black-bg: #fff;
  --border: #d6d9df;
  --bg-1: #f2f6ff;
  --yellow-bg:   #e45d1b;
  --white: #fff;
  --black: #000;
  --black-2: #2a2627;
  --grey: #888888;
  --border: #dddddd;
  --green: #21bf73;
  --red: #d90429;
  --btn: #262391;
  --dark-blue: #0e4051;
  --lot-green:#35a27e;
  --lot-red:#be5150;
  --lot-bg-green:#e7f8f2;
  --lot-bg-red:#f9ebea;
  --lot-datetime:#b8bdc9;
  --text-muted: #8c9097;
  --raxios-muted: linear-gradient(90deg, rgba(215,127,26,1) 0%, rgba(206,121,32,1) 31%, rgba(143,83,74,1) 67%, rgba(74,39,119,1) 100%);
  --raxios-color:rgb(215,127,26);
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

/**====================================
          AUTH SEC CSS START
=======================================**/

section.auth-sec {
  /* min-height: 100vh; */
  display: flex;
}

section.auth-sec .container {
  padding: 25px 20px 0px 20px;
}

.auth-image {
  text-align: center;
}

.auth-image img {
  max-width: 70%;
}

.login-heading h1 {
  color: var(--black-2);
  font-size: 24px;
  font-weight: 600;
}

.login-heading p {
  font-size: 14px;
  font-weight: 400;
  color: var(--grey);
}

.label-box label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-2);
}

.input-group .form-control {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-2);
}

.input-group .form-control::placeholder {
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
}

.input-group .form-control:focus {
  /* background-color: var(--input-bg); */
  border-color: var(--bs-border-color);
}

.input-group .input-group-text {
  background: var(--white-bg);
}

.submit-button .btn-primary {
  width: 100%;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-color: transparent !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: transparent !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-focus-shadow-rgb: transparent !important;
    --bs-btn-active-color: transparent !important;
    --bs-btn-active-bg: transparent !important;
    --bs-btn-active-border-color: transparent !important;
    --bs-btn-active-shadow: transparent !important;
    --bs-btn-disabled-color:transparent !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: transparent !important;
    background: linear-gradient(90deg, rgba(215,127,26,1) 0%, rgba(206,121,32,1) 31%, rgba(143,83,74,1) 67%, rgba(74,39,119,1) 100%);
}

.spinner-border {
  --bs-spinner-width: 1rem !important;
  --bs-spinner-height: 1rem !important;
}

.auth-text p {
  font-size: 13px;
  font-weight: 500;
  color: var(--black);
  text-align: center;
}

.error_msg {
  color:red;
  font-size:10px;
  font-weight: 500;
}


/**================ OTP INPUT FIELD CSS START ================ **/

.otp-input div {
  justify-content: space-between;
  width: 100%;
}

input.otp-input {
  min-height: 20px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid var(--grey-2);
  font-weight: 500;
  background-color: var(--white-bg);
  width: 40px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 7%);
  padding: 10px;
  margin-left:3px;
}

input.otp-input:focus-visible {
  outline: none;
}

/**================ OTP INPUT FIELD CSS FINISH ================ **/


/**====================================
          AUTH SEC CSS FINISH
=======================================**/
</style>
