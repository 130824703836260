<template>
  <div>
    <header class="head">
      <nav class="nav">
        <ul class="nav-list">
          <li>
            <router-link to="/dashboard">
              <img
                src="../assets/images/icon/nav-icon-1.svg"
                alt="nav icon"
                class="default img-fluid"
              />

              <img
                src="../assets/images/icon/nav-icon-active-1.svg"
                alt="nav icon"
                class="active img-fluid"
              />
              
            </router-link>
          </li>

          <li>
            <router-link to="/positions">
              <img
                src="../assets/images/icon/nav-icon-2.svg"
                alt="nav icon"
                class="default img-fluid"
              />

              <img
                src="../assets/images/icon/nav-icon-active-2.svg"
                alt="nav icon"
                class="active img-fluid"
              />
            </router-link>
          </li>

          <li>
            <router-link to="/rank">
              <img
                src="../assets/images/icon/nav-icon-3.svg"
                alt="nav icon"
                class="default img-fluid"
              />

              <img
                src="../assets/images/icon/nav-icon-active-3.svg"
                alt="nav icon"
                class="active img-fluid"
              />
            </router-link>
          </li>

          <li>
            <router-link to="/wallet">
              <img
                src="../assets/images/icon/nav-icon-4.svg"
                alt="nav icon"
                class="default img-fluid"
              />

              <img
                src="../assets/images/icon/nav-icon-active-4.svg"
                alt="nav icon"
                class="active img-fluid"
              />
            </router-link>
          </li>

          <li>
            <router-link to="/setting">
              <img
                src="../assets/images/icon/nav-icon-5.svg"
                alt="nav icon"
                class="default img-fluid"
              />

              <img
                src="../assets/images/icon/nav-icon-active-5.svg"
                alt="nav icon"
                class="active img-fluid"
              />
            </router-link>
          </li>
        </ul>
      </nav>
    </header>
    <!--head-->
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>

<style scoped>
header.head {
    background-color: #fff;
    padding: 15px 15px;
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: 0;
    box-shadow: rgb(50 50 93 / 61%) 0px 2px 5px -1px, rgb(0 0 0 / 58%) 0px 1px 3px -1px;
    border-radius: 20px 20px 0 0;
}

header.head nav.nav {
  width: 100%;
  display: block;
}

header.head nav.nav ul.nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header.head nav.nav ul.nav-list li a img {
    max-width: 25px;
}

header.head nav.nav ul.nav-list li a img.active {
    display: none;
}

header.head nav.nav ul.nav-list li  a.router-link-active.router-link-exact-active img.active {
    display: block;
}

header.head nav.nav ul.nav-list li  a.router-link-active.router-link-exact-active img.default {
    display: none;
}
</style>
